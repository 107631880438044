import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import usePaginatedData from "../../../hooks/usePaginatedData";
import DateFilter from "../filters/DateFilter";
import FilterGroup from "../filters/FilterGroup";
import TextFilter from "../filters/TextFilter";
import Pagination from "../pagination/Pagination";
import {invoiceService} from "../../../services/InvoiceService";
import BillingTable from "../table/invoice/BillingTable";
import Button from "../../atoms/Button";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import {InvoiceSearchObject, InvoiceState} from "../../../interfaces/InvoiceInterfaces";
import AutocompleteMultiselect from "../filters/AutocompleteMultiselect";
import {customerService} from "../../../services/CustomerService";
import SelectFilter from "../filters/SelectFilter";
import {INVOICE_STATUS_OPTIONS, INVOICE_TYPE_OPTIONS} from "../../../constants/OptionConstants";

interface InvoiceListProps {
  className?: string,
  searchObject?: InvoiceSearchObject,
  updateSearchField?: (field: string, value: string | string[]) => void
  onReset?: () => void,
}
const BillingList: FunctionComponent<InvoiceListProps> = ({
  className = "",
  searchObject,
  updateSearchField,
  onReset
}) => {

  const intl = useIntl();

  const {
    data,
    page,
    setPage,
    pageSize,
    setPageSize,
    sortOptions,
    setSortOptions,
    total,
  } = usePaginatedData(invoiceService.getInvoices, searchObject, "invoiceNumber,creationDate", 50, true)

  return (
    <div className={`mb-5 ${className ?? ""}`}>
      <FilterGroup className="d-flex mb-2" >
        <div className="d-flex gap-2">
          <TextFilter
            title={intl.formatMessage({id: "invoice_filter_number"})}
            onChange={(value) => updateSearchField("invoiceNumberOrClient", value)}
            value={searchObject?.invoiceNumber}
            placeholder={intl.formatMessage({id: "invoice_filter_number_placeholder"})}
          />

          <SelectFilter
            options={INVOICE_TYPE_OPTIONS.map(o => ({label: intl.formatMessage({id: o.label}), value: o.value}))}
            onChange={(value) => updateSearchField("type", value)}
            title={intl.formatMessage({id: "invoice_filter_type"})}
            value={searchObject.type}
            isClearable
          />

          <AutocompleteMultiselect
            title={intl.formatMessage({id: "dropdown_header_billing_customer"})}
            placeholder={intl.formatMessage({id: "customer_placeholder"})}
            values={searchObject.customerIds}
            onChange={(values) => updateSearchField("customerIds", values)}
            fetchData={customerService.getCustomersPage}
            filterFieldName="fullName"
            filterFieldSearch="name"
            className="me-2"
            classNameLabel="mb-1 fw-bold"
          />

          <DateFilter onChangeDate={(value) => updateSearchField("startDate", value)} value={searchObject?.startDate} title={intl.formatMessage({id: "order_filter_period_start"})} />
          <DateFilter onChangeDate={(value) => updateSearchField("endDate", value)} value={searchObject?.endDate} title={intl.formatMessage({id: "order_filter_period_end"})} />

          {
            searchObject.status && searchObject.status[0] !== InvoiceState.DRAFT &&
              <SelectFilter
                options={INVOICE_STATUS_OPTIONS.map(o => ({label: intl.formatMessage({id: o.label}), value: o.value}))}
                onChange={(value) => updateSearchField("status", [value])}
                title={intl.formatMessage({id: "invoice_filter_status"})}
                value={searchObject.status.length === 1 ? searchObject.status[0] : ""}
                isClearable
              />
          }

        </div>
        <div className="ms-2">
          <Button onClick={onReset} color={ColorType.SECONDARY}>{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
        </div>
      </FilterGroup>

      <BillingTable invoices={data} sortOptions={sortOptions} onSortOptions={setSortOptions} />
      <Pagination page={page} setPage={setPage} pageSize={pageSize} onPageSizeChange={setPageSize} total={total} />
    </div>
  )
}

export default BillingList;
