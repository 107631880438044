import {Data} from "./TableInterfaces";
import {Contact, HasContact} from "./ContactInterfaces";
import {Params} from "./HttpInterfaces";
import {Address} from "./AddressInterfaces";
import {GenericEntities} from "./GenericEntitiesInterface";
import {Comment} from "./CommentInterface";
import {SearchFilter} from "./api/FiltersInterface";
import {WorkflowStatesEnum} from "../constants/workflow/WorkflowStates";
import {Option} from "./inputs/OptionInterfaces";
import {Tour} from "./TourInterfaces";
import {PublicType} from "../constants/ResourceConstants";

export interface CustomerFields extends Data, Params, Address {
  name?: string;
  firstname?: string;
  code?: string;
  paymentCondition?: PaymentCondition,
}

export interface InvoiceOperationCustomerFields extends Params {
  individual?: string;
  geographicalZone?: string;
  numberIntraVAT?: string;
  invoiceForCustomer?: string;
  billingServices?: Option<string>[];
  defaultBillingServiceId?: string;
  disbursement?: string;
  isVoucherManagement?: string;
  mainSortInvoice?: string;
  secondarySortInvoice?: string;
  isReservationManagement?: string;
  numberWeeksViewed?: number;
  notificationForActivity?: string;
  notificationForReplacement?: string;
  publicType?: PublicType,
  paymentCondition?: PaymentCondition;
  siren?: string;
  disbursementAccount?: string;
}

export interface CustomerBillingService {
  customerId?: string;
  billingServiceId?: string;
  isDefault?: boolean;
}

export interface Customer extends Data, GenericEntities, HasContact {
  id?: string;
  billingServices?: CustomerBillingService[];
  billingServicesAsCustomers?: SimpleCustomer[];
  defaultBillingServiceAsCustomer?: SimpleCustomer;
  contact?: Contact;
  tourCount?: number;
  orderCount?: number;
  tourLeaderCount?: number;
  address?: Address;
  name?: string;
  firstname?: string;
  code?: string;
  isReservationManagement?: boolean;
  isVoucherManagement?: boolean;
  mainSortInvoice?: string;
  secondarySortInvoice?: string;
  numberWeeksViewed?: number;
  numberIntraVAT?: string;
  geographicalZone?: string;
  invoiceForCustomer?: boolean;
  disbursement?: boolean;
  notificationForActivity?: boolean;
  notificationForReplacement?: boolean;
  modificationUser?: string;
  numberTours?: number;
  numberOrders?: number;
  comment?: Comment;
  paymentCondition?: PaymentCondition,
  publicType?: PublicType,
  individual?: boolean;
  siren?: string;
  disbursementAccount?: string;
}

export interface CustomerIdentity extends Data {
  id?: string;
  name?: string;
  code?: string;
  geographicalZone?: string;
  numberWeeksViewed?: number;
}

export interface CustomerWithTour extends Customer {
  tours?: Tour[],
}

export interface SimpleCustomer extends Data {
  name?: string;
  code?: string;
  active?: boolean;
  isReservationManagement?: boolean
}

export interface CustomerSearchObject extends SearchFilter {
  name?: string,
  currentState?: WorkflowStatesEnum,
  id?: string,
  orderCustomerId?: string,
  tourLeaderId?: string,
  tourId?: string,
  excludedIds?: string[],
}


export interface BillingServiceSearchObject extends SearchFilter {
  name?: string,
  customerId?: string,
}

export const enum PaymentCondition {
  AT_RECEPTION = "AT_RECEPTION",
  THIRTY_DAYS_AFTER_RECEPTION = "THIRTY_DAYS_AFTER_RECEPTION",
}

export interface RemindInvoiceCustomerMail {
  template: string;
}

export interface RemindInvoiceCustomerMailBody {
  template: string;
  reminderPdf: boolean;
  invoiceId: string;
}
