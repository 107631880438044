import * as Yup from "yup";
import {
  getRequiredStringForCondition, yupNotRequiredSiren,
  yupRequiredArray,
  yupRequiredString, yupVATNumber
} from "../yup/Validators";
import {TRUE_FALSE_ENUM} from "../OptionConstants";

export const CreateCustomerSchema = Yup.object().shape({
  name: yupRequiredString,
  firstname: getRequiredStringForCondition("individual", (individual) => individual === TRUE_FALSE_ENUM.TRUE),
  code: yupRequiredString,
  mainLabel: yupRequiredString,
  zipCode: yupRequiredString,
  city: yupRequiredString,
  country: yupRequiredString
});

export const CreateInvoiceOperationCustomerSchema = Yup.object().shape({
  geographicalZone: yupRequiredString,
  numberIntraVAT: yupVATNumber,
  siren: yupNotRequiredSiren,
  invoiceForCustomer: yupRequiredString,
  billingServices: yupRequiredArray(1),
  defaultBillingServiceId: yupRequiredString,
  disbursement: yupRequiredString,
  isVoucherManagement: yupRequiredString,
  isReservationManagement: yupRequiredString,
  numberWeeksViewed: Yup.number().required("mandatory_field"),
  notificationForActivity: yupRequiredString,
  notificationForReplacement: yupRequiredString,
  publicType: yupRequiredString,
})
