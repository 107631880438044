import React, {FunctionComponent, useMemo, useRef, useState} from "react";
import {tableUtils} from "../../../utils/tableUtils";
import {FormattedMessage, useIntl} from "react-intl";
import Button from "../../atoms/Button";
import FilterGroup from "../filters/FilterGroup";
import TextFilter from "../filters/TextFilter";
import useSearchFilter from "../../../hooks/useSearchFilter";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import DateFilter from "../filters/DateFilter";
import {PageResponse, PaginationQueryParams} from "../../../interfaces/api/PaginationInterface";
import {orderItemService} from "../../../services/OrderItemService";
import {ORDER_ITEM_TABLE} from "../../../constants/Table";
import {customerService} from "../../../services/CustomerService";
import {
  OrderItemResponse,
  OrderItemSearchObject,
  OrderItemState,
  RemoveVoucherFromOrderItemRequest
} from "../../../interfaces/OrderItemInterfaces";
import PagedTableWithRowSelection, {PagedTableRef} from "../table/PagedTableWithRowSelection";
import {invoiceService} from "../../../services/InvoiceService";
import {Row} from "react-table";
import {Data} from "../../../interfaces/TableInterfaces";
import {toastUtils} from "../../../utils/toastUtils";
import {dateUtils} from "../../../utils/dateUtils";
import ModalGenerateInvoice from "../modal/ModalGenerateInvoice";
import {useNavigate} from "react-router-dom";
import {INVOICES_PATH} from "../../../constants/routes/RoutePaths";
import {WorkflowStatesEnum} from "../../../constants/workflow/WorkflowStates";
import {Customer, CustomerSearchObject} from "../../../interfaces/CustomerInterfaces";
import AutocompleteFilterPageable from "../filters/AutocompleteFilterPageable";
import Toggle from "../../atoms/Toggle";
import {VoucherStatesEnum} from "../../../constants/voucher/VoucherStates";
import Panel from "../panel/Panel";
import useLoading from "../../../hooks/useLoading";
import RemindResourceForm from "../form/RemindResourceForm";
import ModalDenyVoucher from "../modal/orderitem/ModalDenyVoucher";
import {ORDER_ITEM_BILLING_STATUS_OPTIONS} from "../../../constants/OptionConstants";
import SelectFilter from "../filters/SelectFilter";

interface BillingListProps {
  className?: string
}

const PreBillingList: FunctionComponent<BillingListProps> = ({
  className = ""
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const initialSearchState: OrderItemSearchObject = useMemo(() => ({
    codeOrReference: "",
    customersIds: [],
    billingServicesIds: [],
    status: [OrderItemState.VALIDATED, OrderItemState.CANCELLED, OrderItemState.PENDING],
    billed: false,
    startDate: undefined,
    endDate: undefined,
  }), [])

  const {searchObject, onReset, updateSearchField} = useSearchFilter<OrderItemSearchObject>(
    initialSearchState,
    {
      mutateInitialState: (params: URLSearchParams) => {
        // If there's no params, then we add start/end date to default filters!
        if (Array.from(params.keys()).length === 0) {
          return {
            ...initialSearchState,
            startDate: dateUtils.formatDateYYYYMMDD(dateUtils.firstDayOfLastMonth()),
            endDate: dateUtils.formatDateYYYYMMDD(dateUtils.lastDayOfLastMonth()),
          }
        }
        return initialSearchState
      }
    })

  const [openPreinvoiceModal, setOpenPreinvoiceModal] = useState(false)
  const [selectedDatas, setSelectedDatas] = useState<OrderItemResponse[]>([])
  const [billingCustomersBool, setBillingCustomersBool] = useState<boolean>(false);
  const [remindResourcePanelOpen, setRemindResourcePanelOpen] = useState<boolean>(false);
  const [selectedOrderItem, setSelectedOrderItem] = useState<OrderItemResponse>(null)
  const [selectedFileId, setSelectedFileId] = useState<string>(null)
  const [openDenyVoucherModal, setOpenDenyVoucherModal] = useState(false);
  const {loading, stopLoading, startLoading} = useLoading()

  const ref = useRef<PagedTableRef>()

  const getBillingCustomers = useMemo(() => (page: PaginationQueryParams, filter: CustomerSearchObject): Promise<PageResponse<Customer>> => {
    return customerService.getCustomersPage(page, {...filter, currentState: WorkflowStatesEnum.ACTIVE, orderCustomerId: searchObject?.customersIds?.toString()})
  }, [billingCustomersBool]);

  const handleCheckButtonClick = (orderItemResponse: OrderItemResponse, fileId: string) => {
    setSelectedFileId(fileId);
    setSelectedOrderItem(orderItemResponse);
    setOpenDenyVoucherModal(true);
  };

  const handleRemindButtonClick = (orderItemResponse: OrderItemResponse) => {
    setRemindResourcePanelOpen(true);
    setSelectedOrderItem(orderItemResponse);
  };

  const handleSendMail = (content: RemoveVoucherFromOrderItemRequest) => {
    orderItemService.removeVoucherFromOrderItem(selectedFileId, content.template, selectedOrderItem.id)
      .then(() => toastUtils.successToast(intl.formatMessage({id: "resource_notify_remove_voucher_toast_success"})))
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "resource_notify_remove_voucher_toast_error"})))
      .finally(() => {
        setOpenDenyVoucherModal(false);
        ref.current?.refresh();
      });
  }

  const handleValidateButtonClick = (orderItemResponse: OrderItemResponse) => {
    orderItemService.validateVoucherForOrderItem(orderItemResponse.id)
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "resource_validate_voucher_success"}))
        ref.current?.refresh()
      })
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "resource_validate_voucher_error"})))
  }

  const columns = useMemo(() => tableUtils.getColumns(ORDER_ITEM_TABLE(intl, handleRemindButtonClick, handleCheckButtonClick, handleValidateButtonClick)), []);
  const getOrders = (query: PaginationQueryParams, filter: OrderItemSearchObject) => {
    return orderItemService.getOrderItemsPage(query, filter)
  }

  const handleItemsMissingVoucher = (checked: boolean) => {
    checked ? updateSearchField("voucherStatus", VoucherStatesEnum.MISSING) : updateSearchField("voucherStatus", "")
  }

  const onValidate = () =>
    invoiceService.createInvoice(selectedDatas.map(data => data.id))
      .then(() => {
        ref.current?.refresh()
        setSelectedDatas([])
        toastUtils.successToast(intl.formatMessage({id:"billing_generate_preinvoice_success"}))
        navigate(`${INVOICES_PATH}`)
      })
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "billing_generate_preinvoice_error"})))
      .finally(() => {
        setOpenPreinvoiceModal(false)
      });

  const updateStatusSearchField = (status: string) => {
     updateSearchField("status", status ? [status] :[OrderItemState.VALIDATED, OrderItemState.CANCELLED, OrderItemState.PENDING])
  }

  return (
    <>
      <FilterGroup className="gap-2">
        <TextFilter
          value={searchObject?.codeOrReference}
          onChange={(value) => updateSearchField("codeOrReference", value)}
          title={intl.formatMessage({id: "order_item_order_number"})}
        />

        <AutocompleteFilterPageable
          onChange={(value) => updateSearchField("customersIds", value)}
          title={intl.formatMessage({id: "dropdown_header_order_customer"})}
          value={searchObject?.customersIds?.toString()}
          fetchData={(page, filter) => customerService.getCustomersPage(page, {...filter, currentState: WorkflowStatesEnum.ACTIVE})}
          onBlur={() => setBillingCustomersBool(!billingCustomersBool)}
          filterFieldName="fullName"
          filterFieldSearch="name"
        />

        <AutocompleteFilterPageable
          onChange={(value) => updateSearchField("billingServicesIds", value)}
          title={intl.formatMessage({id: "dropdown_header_billing_customer"})}
          value={searchObject?.billingServicesIds?.toString()}
          fetchData={getBillingCustomers}
          filterFieldName="fullName"
          filterFieldSearch="name"
        />

        <DateFilter
          value={searchObject?.startDate}
          onChangeDate={(value) => updateSearchField("startDate", value)}
          title={intl.formatMessage({id: "billing_from"})}
        />

        <DateFilter
          value={searchObject?.endDate} onChangeDate={(value) => updateSearchField("endDate", value)}
          title={intl.formatMessage({id: "billing_to"})}
        />

        <SelectFilter
          options={ORDER_ITEM_BILLING_STATUS_OPTIONS.map(o => ({label: intl.formatMessage({id: o.label}), value: o.value}))}
          onChange={updateStatusSearchField}
          title={intl.formatMessage({id: "invoice_filter_status"})}
          value={searchObject.status.length === 1 ? searchObject.status[0] : ""}
          isClearable
        />

        <Button
          onClick={() => onReset()}
          color={ColorType.SECONDARY}>{intl.formatMessage({id: "header_menu_clear_search"})}
        </Button>
      </FilterGroup>

      <div className="d-flex align-items-center justify-content-end ms-auto mb-3">
        <div className="d-flex align-items-center bold">
          <FormattedMessage id="billing_table_filter_missing_voucher"/>
          <Toggle className="ms-2" id="billing_table_filter_missing_voucher" onChange={handleItemsMissingVoucher}/>
        </div>
      </div>

      <ModalGenerateInvoice
        datas={selectedDatas}
        onValidate={onValidate}
        onCancel={() => setOpenPreinvoiceModal(false)}
        open={openPreinvoiceModal}
      />

      <PagedTableWithRowSelection
        ref={ref}
        columns={columns}
        labelNoResult="billing_no_result"
        className={`${className} table`}
        filters={searchObject}
        fetchData={getOrders}
        rowSelectActionTitle="billing_create_invoice"
        onRowSelectAction={(selectedRows: Array<Row<Data>>) => {
          setSelectedDatas(selectedRows.map(i => i.original as OrderItemResponse))
          setOpenPreinvoiceModal(true)
        }}
        defaultSortBy={[{sortBy: "rendezVousDate"}]}
      />
      {remindResourcePanelOpen && (
          <Panel
            formId="remindResourceForm"
            title={intl.formatMessage({id: "resource_remind_voucher_upload_title"})}
            loading={loading}
            open={remindResourcePanelOpen}
            displayValidateButton={false}
            onCancel={() => setRemindResourcePanelOpen(false)}
          >
            <RemindResourceForm orderItem={selectedOrderItem} id="remindResourceForm" stopLoading={stopLoading} startLoading={startLoading}/>
          </Panel>
        )
      }
      {selectedOrderItem && <ModalDenyVoucher
        onSubmit={(content) => handleSendMail(content)}
        orderItem={selectedOrderItem}
        open={openDenyVoucherModal}
        onOpen={(open) => setOpenDenyVoucherModal(open)}
        onClose={() => setOpenDenyVoucherModal(false)}/>
      }
    </>
  )
}

export default PreBillingList;
