import _ from "lodash";
import React, {FunctionComponent, useEffect, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import ReactSelect, {MenuListProps, components, OptionProps} from "react-select";
import {SearchFilter} from "../../../interfaces/api/FiltersInterface";
import {PageResponse} from "../../../interfaces/api/PaginationInterface";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import {Data} from "../../../interfaces/TableInterfaces";
import {toastUtils} from "../../../utils/toastUtils";
import FilterLabel from "./FilterLabel";

export interface AutocompleteMultiselectProps {
  className?: string
  classNameLabel?: string
  title?: string
  placeholder?: string
  onChange: (values: string[], selectedOptions?: Option<string>[]) => void
  values?: string[]
  fetchData: (page, filter: SearchFilter) => Promise<PageResponse<Data>>
  filterFieldName: string
  filterFieldSearch?: string
  id?: string
  page?: number
  pageSize?: number
  labelJoiner?: string
  filterOption?: (option: Option<string>) => boolean
}

const AutocompleteMultiselect: FunctionComponent<AutocompleteMultiselectProps> = ({
  className = "",
  classNameLabel = "",
  title,
  placeholder,
  onChange,
  values,
  fetchData,
  filterFieldName,
  filterFieldSearch= filterFieldName,
  id,
  page = 0,
  pageSize = 50,
  labelJoiner = " - ",
  filterOption = () => true
}) => {
  const intl = useIntl()
  const [selectedOptions, setSelectedOptions] = useState<Option<string>[]>([])
  const [options, setOptions] = useState<Option<string>[]>([])
  const [filter, setFilter] = useState<SearchFilter>(null)
  const [total, setTotal] = useState<number>()
  const [inputValue, setInputValue] = useState<string>("")

  const loadData = ({page, pageSize}, filter) => {
    fetchData({page, pageSize}, filter)
      .then((response: PageResponse<Data>) => {
        const options = response?.content?.map(data => {
          const label = filterFieldName.split(",").map(key => _.get(data, key)).join(labelJoiner)
          return {label: label, value: data.id}
        })

        const filteredOptions = options.filter(option => !selectedOptions.some(so => so.value === option.value))
        setOptions([...selectedOptions, ...filteredOptions])
        setTotal(response.totalElements)
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "autocomplete_error"}))
      })
  }

  useEffect(() => {
    if (fetchData && filter != null) {
      loadData({ page, pageSize }, filter)
    }
  }, [filter])

  useEffect(() => {
    if (values?.length > 0) {
      loadData({ page, pageSize }, filter)
    }
  }, [fetchData])

  useMemo(() => {
    const initialOptions = options?.filter((option) => values.includes(option?.value)) ?? []
    setSelectedOptions(initialOptions)
  }, [values, options])

  const handleOptionSelection = (selected: Option<string>) => {
    let updated: Option<string>[] = []

    if (selectedOptions.map(o => o.value).includes(selected?.value)) {
      updated = [...selectedOptions].filter(option => option.value !== selected.value)
      setSelectedOptions(updated)

    } else if (selected !== null) {
      updated = [...selectedOptions, selected]
      setSelectedOptions(updated)
    }
    onChange(updated.map(o => o?.value), updated)
    setFilter({[filterFieldSearch]: inputValue})
    setInputValue(inputValue)
  }

  const CustomOption = (props: OptionProps<Option<string>>) => {
    return components.Option && (
      <components.Option {...props}>
        <input
          className="me-2"
          type="checkbox"
          readOnly
          checked={selectedOptions.map(o => o.value).includes(props.data?.value)}
        />
        {props.data.label}
      </components.Option>)
  }

  const SelectMenuButton = (props: MenuListProps) => {
    return (
      <components.MenuList {...props} className="d-flex flex-column">
        {props.children}
        {total > pageSize && (
          <div className="d-flex ms-auto pe-3 pb-1 fs-12">
            <span>{intl.formatMessage({id: "autocomplete_results_size"}, {total: total - pageSize})}</span>
          </div>
        )}
      </components.MenuList>
    )
  }

  const formatOptionLabel = () => {
    const label = selectedOptions[0]?.label.length > 30
      ? selectedOptions[0]?.label.substring(0, 27) + "..."
      : selectedOptions[0]?.label

    return selectedOptions.length > 1
      ? `${label} +${selectedOptions.length - 1}`
      : label
  }

  return (
    <div className={` ${className}`}>
      {title && <FilterLabel className={classNameLabel} text={title} />}

      <ReactSelect
        onFocus={() => loadData({page, pageSize}, filter)}
        id={id ? id : `autocomplete-${title}`}
        placeholder={placeholder ?? ""}
        onChange={handleOptionSelection}
        options={options}
        className={`epow-select ${selectedOptions.length > 0 ? "selected" : ""}`}
        inputValue={inputValue}
        onInputChange={(value) => {
          setInputValue(value)
          setFilter({[filterFieldSearch]: value})
        }}
        filterOption={filterOption}
        isClearable
        closeMenuOnSelect={false}
        value={selectedOptions}
        formatOptionLabel={() => formatOptionLabel()}
        styles={{
          placeholder: (defaultStyles) => {
            return {
              ...defaultStyles,
              color: "#8A9095",
              fontStyle: "normal",
              fontSize: "14px"
            }
          }
        }}
        components={{MenuList: SelectMenuButton, Option: CustomOption}}
      />
    </div>
  )
}

export default AutocompleteMultiselect
