import React, {FunctionComponent, useMemo} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import usePaginatedData from "../../../hooks/usePaginatedData";
import useSearchFilter from "../../../hooks/useSearchFilter";
import {ActivityBillingSearchObject, ActivityBillingStatus} from "../../../interfaces/ActivityBllingInterface";
import {Resource} from "../../../interfaces/ResourceInterfaces";
import {activityBillingService} from "../../../services/ActivityBillingService";
import {resourceService} from "../../../services/ResourceService";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import Button from "../../atoms/Button";
import DateFilter from "../filters/DateFilter";
import FilterGroup from "../filters/FilterGroup";
import TextFilter from "../filters/TextFilter";
import Pagination from "../pagination/Pagination";
import ActivityBillingOverviewTable from "../table/activityBilling/ActivityBillingOverviewTable";
import AutocompleteFilterPageable from "../filters/AutocompleteFilterPageable";
import HeaderButtonGroup, {HeaderStateButton} from "../header/HeaderButtonGroup";
import PageTitle from "../header/PageTitle";
import AutocompleteMultiselect from "../filters/AutocompleteMultiselect";
import {resourceTypeService} from "../../../services/ResourceTypeService";
import ActivityBillingOverviewTableRowTotal from "../table/activityBilling/ActivityBillingOverviewTableRowTotal";
import {useNavigate} from "react-router-dom";
import {PAYMENT_PATH} from "../../../constants/routes/RoutePaths";
import {ActivityBillingOverviewListCounts} from "../../../interfaces/CounterInterfaces";

interface ActivityBillingOverviewListProps {
  className?: string,
}

const ActivityBillingOverviewList: FunctionComponent<ActivityBillingOverviewListProps> = ({
  className = "",
}) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const initialSearchObject: ActivityBillingSearchObject = {
    resourceId: "",
    number: "",
    startDate: "",
    endDate: "",
    status: ActivityBillingStatus.TO_CHECK,
    resourceTypeIds: []
  }
  const {searchObject, updateSearchField, onReset} = useSearchFilter<ActivityBillingSearchObject>(initialSearchObject)
  const {
    data,
    page,
    setPage,
    pageSize,
    setPageSize,
    sortOptions,
    setSortOptions,
    total,
    refresh
  } = usePaginatedData(activityBillingService.getActivityBillingPage, searchObject, "date", 50, "DES")

  const [counters, setCounters] = React.useState<ActivityBillingOverviewListCounts>({
    toCheck: "0",
    accepted: "0",
  })


  const getActivityBillingPage = async (status: ActivityBillingStatus): Promise<string> => {
    return await activityBillingService.getActivityBillingPage({pageSize: 1}, {status: status})
      .then((res) => res.totalElements)
      .catch(() => null);
  }

  useMemo(() => {
    const loadData = async () => {
      setCounters({
        toCheck: await getActivityBillingPage(ActivityBillingStatus.TO_CHECK) ?? "0",
        accepted: await getActivityBillingPage(ActivityBillingStatus.ACCEPTED) ?? "0",
      })
    }

    loadData().catch(() => null)
  }, [data]);



  const stateFilterButtons: HeaderStateButton[] = useMemo(() => [
    {
      title: intl.formatMessage({id: "activity_bill_filter_header_to_check"}),
      badge: counters.toCheck,
      onclick: () => {
        setSortOptions({descending: false, sortBy: "date"})
        updateSearchField("status", ActivityBillingStatus.TO_CHECK)
      },
      active: searchObject.status === ActivityBillingStatus.TO_CHECK
    },
    {
      title: intl.formatMessage({id: "activity_bill_filter_header_unpaid"}),
      badge: counters.accepted,
      onclick: () => {
        setSortOptions({descending: false, sortBy: "date"})
        updateSearchField("status", ActivityBillingStatus.ACCEPTED)
      },
      active: searchObject.status === ActivityBillingStatus.ACCEPTED
    },
    {
      title: intl.formatMessage({id: "activity_bill_filter_header_paid"}),
      onclick: () => {
        setSortOptions({descending: true, sortBy: "date"})
        updateSearchField("status", ActivityBillingStatus.SETTLED)
      },
      active: searchObject.status === ActivityBillingStatus.SETTLED
    }], [intl, counters])

  return (
    <div className={className}>
      <div className="d-flex mb-5">
        <PageTitle pageTitle={intl.formatMessage({id: "billing_overview_title"})}></PageTitle>
        <div className="d-flex justify-content-center flex-1">
          <HeaderButtonGroup buttonList={stateFilterButtons} />
        </div>
        <div>
          <Button className="btn-header" onClick={() => navigate(PAYMENT_PATH)}><FormattedMessage id={"payment_navigate_btn"}></FormattedMessage></Button>
        </div>
      </div>

      <FilterGroup className="d-flex gap-2 mb-3">
        <AutocompleteMultiselect
          title={intl.formatMessage({id: "resource_type"})}
          placeholder={intl.formatMessage({id: "resource_type"})}
          values={searchObject.resourceTypeIds ?? []}
          onChange={(values) => updateSearchField("resourceTypeIds", values)}
          fetchData={resourceTypeService.getResourceTypePage}
          filterFieldName="label"
          className="me-2"
          classNameLabel="mb-1 fw-bold"
        />

        <AutocompleteFilterPageable
          title={intl.formatMessage({id: "billing_overview_resource"})}
          value={searchObject?.resourceId}
          onChange={(value) => updateSearchField("resourceId", value)}
          fetchData={resourceService.getResourcesPage}
          filterFieldName="nameSurname"
          manageOptions={(response: Resource[]) => response.map(option =>
            ({value: option.id, label: `${option.identity?.firstName} - ${option.identity?.lastName}`})
          )}
          optionFromValue={(response: Resource[], value: string) => {
            const option = response?.find((resource) => resource.id === value);
            return option ? {
              label: `${option.identity?.firstName} - ${option.identity?.lastName}`,
              value: option?.id
            } : null;
          }}
        />

        <DateFilter
          title={intl.formatMessage({id: "billing_overview_period_start"})}
          value={searchObject.startDate}
          onChangeDate={(value) => updateSearchField("startDate", value)}
        />

        <DateFilter
          title={intl.formatMessage({id: "billing_overview_period_end"})}
          value={searchObject.endDate}
          onChangeDate={(value) => updateSearchField("endDate", value)}
        />

        <TextFilter
          title={intl.formatMessage({id: "billing_overview_number"})}
          value={searchObject.number}
          onChange={(value) => updateSearchField("number", value)}
          placeholder={intl.formatMessage({id: "billing_overview_number_placeholder"})}
        />

        <div>
          <Button onClick={() => onReset({status: searchObject.status})} color={ColorType.SECONDARY} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
        </div>
      </FilterGroup>

      <ActivityBillingOverviewTable
        refresh={refresh}
        activityBillings={data}
        sortOptions={sortOptions}
        onSortOptions={setSortOptions}
        disableSelect={searchObject.status === ActivityBillingStatus.SETTLED}
        status={searchObject.status}
      />
      <ActivityBillingOverviewTableRowTotal activityBillingPageData={data}/>
      <Pagination page={page} setPage={setPage} pageSize={pageSize} onPageSizeChange={setPageSize} total={total} />
    </div>
  )
}

export default ActivityBillingOverviewList;
