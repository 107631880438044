import {SearchFilter} from "./api/FiltersInterface";
import {Customer} from "./CustomerInterfaces";
import {Params} from "./HttpInterfaces";
import {OrderItem} from "./OrderItemInterfaces";
import {Data} from "./TableInterfaces";
import {TourLeader, TourLeaderIdentity} from "./TourLeaderInterfaces";
import {Tour} from "./TourInterfaces";
import {ColorType} from "../types/bootstrap/BootstrapType";
import {StateMap} from "../constants/workflow/StateConstants";
import {Option} from "./inputs/OptionInterfaces";


export interface OrderOption {
  id: string,
  orderNumber: string,
  refCustomer: string,
  customerName: string
}
export interface Order extends Data {
  modificationTourLeader?: string,
  tour?: Tour,
  tourLeaders?: TourLeader[],
  customer?: Customer,
  orderNumber: string,
  tourLeaderIds: string[],
  date?: string,
  beginTourDate?: string,
  isSentToCustomer?: boolean,
  orderComment?: string,
  modificationComment?: string,
  modificationUser?: string,
  isValidated?: boolean,
  customerReference: string,
  creationDate: string,
  orderStatus: string,
  items?: OrderItem[],
  orderRefDisplay?: string,
  defaultPax?: number,
  paxConfirmed?: boolean,
  shouldUpdateOrderItemPax?: boolean,
  mailTourLeaderSent?: boolean,
}

export interface OrderList extends Data {
  id?: string,
  tourLeaders?: TourLeaderIdentity[],
  orderNumber?: string,
  customer?: Customer,
  customerReference?: string,
  orderStatus?: string,
  orderComment?: string,
  defaultPax?: number,
  paxConfirmed?: boolean,
  shouldUpdateOrderItemPax?: boolean,
  items?: OrderItem[],
  orderRefDisplay?: string,
  beginTourDate?: string,
  mailTourLeaderSent?: boolean,
}

export interface OrderBasicDto extends Data {
  orderNumber?: string,
  customerReference?: string,
  customerName?: string,
  date?: string,
  orderComment?: string,
}

export const enum OrderImportError {
  UNKNOWN_CUSTOMER,
  UNKNOWN_TOUR,
  INVALID_CUSTOMER,
  INVALID_TOUR,
  INVALID_DATE
}


export const enum OrderState {
  DRAFT = "DRAFT",
  PENDING = "PENDING",
  HANDLED = "HANDLED",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED"
}

export const enum OrderStateView {
  HOME = "HOME",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
  HANDLED = "HANDLED",
  PENDING = "PENDING",
  DRAFT = "DRAFT",
}

export const ORDER_STATUS_PENDING = [OrderState.DRAFT, OrderState.PENDING, OrderState.HANDLED];


export const OrderStateMap: StateMap = {
  DRAFT: {
    id: OrderState.DRAFT,
    label: "order_state_draft",
    color: ColorType.SECONDARY,
  },
  PENDING: {
    id: OrderState.PENDING,
    label: "order_state_pending",
    color: ColorType.WARNING,
  },
  HANDLED: {
    id: OrderState.HANDLED,
    label: "order_state_handled",
    color: ColorType.PRIMARY,
  },
  COMPLETED: {
    id: OrderState.COMPLETED,
    label: "order_state_completed",
    color: ColorType.PRIMARY,
  },
  CANCELLED: {
    id: OrderState.CANCELLED,
    label: "order_state_cancelled",
    color: ColorType.TERTIARY,
  }
}

export interface OrderCsvItem {
  lineNumber: number,
  client: string,
  tour: string,
  referenceClient: string,
  dayZeroTour: Date,
  date: Date,
  errors: OrderImportError[]
}

export interface OrderField {
  id?: string,
  customerId: string,
  tourId: string,
  tourLeaderIds?: Option<string>[],
  isValidated: boolean,
  customerReference: string,
  dayZeroTour: string,
  date: string,
  orderNumber: string
  defaultPax?: number,
  paxConfirmed?: boolean,
  shouldUpdateOrderItemPax?: boolean,
  orderComment?: string;
}

export interface OrderRequest extends Params {
  id?: string,
  customerId: string,
  orderCodeCustomer: string,
  dayZeroTour: string,
  date: string,
}

export interface OrderMassRequest extends Params {
  orderId?: string,
  tourLeaderId?: string,
  defaultPax?: number,
  paxConfirmed?: boolean
}

export interface OrderSearchObject extends SearchFilter {
  orderId?: string,
  customerOrCode?: string,
  customerId?: string,
  tourLeaderId?: string,
  tourId?: string,
  status?: string[] | string,
  startDate?: string,
  endDate?: string,
  id?: string,
  isBooking?: boolean
  billingServiceId?: string,
  hasNullPaxConfirmOrTourLeader?: boolean,
}

