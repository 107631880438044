import React, {FunctionComponent, useEffect} from "react";
import {useIntl} from "react-intl";
import {BookingSearchObject, BookingStatus} from "../../../interfaces/BookingInterfaces";
import useSearchFilter from "../../../hooks/useSearchFilter";
import usePaginatedData from "../../../hooks/usePaginatedData";
import FilterGroup from "../filters/FilterGroup";
import DateFilter from "../filters/DateFilter";
import BookingTable from "../table/booking/BookingTable";
import Pagination from "../pagination/Pagination";
import {bookingService} from "../../../services/BookingService";
import AutocompleteMultiselect from "../filters/AutocompleteMultiselect";
import {customerService} from "../../../services/CustomerService";
import {supplierService} from "../../../services/SupplierService";
import {WorkflowStatesEnum} from "../../../constants/workflow/WorkflowStates";
import {serviceService} from "../../../services/ServiceService";
import TextFilter from "../filters/TextFilter";

interface BookingListProps {
  className?: string,
  status?: BookingStatus,
  defaultPendingBookingToOpenId?: string
}
const BookingList: FunctionComponent<BookingListProps> = ({
  className = "",
  status,
  defaultPendingBookingToOpenId,
}) => {
  const intl = useIntl()

  const initialSearchState: BookingSearchObject = {
    supplierIds: [],
    serviceIds: [],
    customerIds: [],
    startDate: "",
    endDate: "",
    query: undefined,
    status: [status],
  }
  const {searchObject, updateSearchField} = useSearchFilter<BookingSearchObject>(initialSearchState)

  const {
    data,
    page,
    setPage,
    pageSize,
    setPageSize,
    sortOptions,
    setSortOptions,
    total,
    refresh
  } = usePaginatedData(bookingService.getBookingsPage, searchObject)

  useEffect(() => {
    updateSearchField("status", [status]);
  }, [status]);

  return (
    <div className={`${className} mb-5`}>
      <FilterGroup className="d-flex gap-2">
        <TextFilter
          className="main-filter"
          title={intl.formatMessage({id: "booking_filter_order"})}
          value={searchObject.query}
          onChange={(value) => updateSearchField("query", value)}
          placeholder={intl.formatMessage({id: "booking_filter_order_placeholder"})}
        />
        <AutocompleteMultiselect
          title={intl.formatMessage({id: "booking_filter_supplier"})}
          values={searchObject.supplierIds}
          onChange={(values) => updateSearchField("supplierIds", values)}
          fetchData={(page, filters) => supplierService.getSuppliersPaginated(page, {...filters, currentState: WorkflowStatesEnum.ACTIVE})}
          filterFieldName="name"
          filterFieldSearch="name"
        />
        <AutocompleteMultiselect
          title={intl.formatMessage({id: "booking_filter_service"})}
          values={searchObject.serviceIds}
          onChange={(values) => updateSearchField("serviceIds", values)}
          fetchData={(page, filters) => serviceService.getServicesPage(page, {...filters, currentState: WorkflowStatesEnum.ACTIVE})}
          filterFieldName="name"
          filterFieldSearch="name"
        />

        <AutocompleteMultiselect
          title={intl.formatMessage({id: "booking_filter_customer"})}
          values={searchObject.customerIds}
          onChange={(values) => updateSearchField("customerIds", values)}
          fetchData={(page, filters) => customerService.getActiveCustomers(page, filters)}
          filterFieldName="fullName"
          filterFieldSearch="name"
        />
        <DateFilter
          value={searchObject.endDate}
          onChangeDate={(value) => updateSearchField("startDate", value)}
          title={intl.formatMessage({id: "filter_from"})}
        />
        <DateFilter
          value={searchObject.startDate}
          onChangeDate={(value) => updateSearchField("endDate", value)}
          title={intl.formatMessage({id: "filter_to"})}
        />
      </FilterGroup>

      <BookingTable status={status} bookings={data} sortOptions={sortOptions} onSortOptions={setSortOptions} refresh={refresh} defaultPendingBookingToOpenId={defaultPendingBookingToOpenId}/>
      <Pagination page={page} setPage={setPage} pageSize={pageSize} onPageSizeChange={setPageSize} total={total}/>
    </div>
  )
}

export default BookingList
