import {BookingFields} from "../../../../interfaces/PurchaseInterfaces";
import React, {FunctionComponent, useEffect} from "react";
import {BookingPriceResponse, BookingStatus, BookingTableDto} from "../../../../interfaces/BookingInterfaces";
import {Formik, FormikValues} from "formik";
import {Col, Input} from "reactstrap";
import TextLink from "../../../atoms/TextLink";
import {ORDERS_PATH} from "../../../../constants/routes/RoutePaths";
import {dateUtils} from "../../../../utils/dateUtils";
import {useIntl} from "react-intl";
import {BookingForPurchaseCreationValidationSchema} from "../../../../constants/validation/BookingValidationSchemas";
import FormInput from "../../input/FormInput";
import FormAutocompleteSelectPageable from "../../input/FormAutocompleteSelectPageable";
import {supplierService} from "../../../../services/SupplierService";
import {numberUtils} from "../../../../utils/numberUtils";
import EpowForm from "../../form/EpowForm";
import {orderUtils} from "../../../../utils/orderUtils";
import Popover from "../../../atoms/Popover";
import {IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import Icon from "../../../icon/Icon";
import {bookingService} from "../../../../services/BookingService";
import {toastUtils} from "../../../../utils/toastUtils";
import {ApiError} from "../../../../interfaces/ErrorInterfaces";

interface BookingTableRowProps {
  className?: string,
  index: number,
  status?: BookingStatus,
  booking: BookingTableDto,
  rowOpen: {[index: string]: boolean},
  setRowOpen: (bookingId: {[index: string]: boolean}) => void,
  setFormOpen: (formOpen: boolean) => void,
  setBooking: (booking: BookingTableDto) => void,
  bookingForm: BookingFields
  isSelected: boolean,
  handleValueChange: (booking: BookingFields) => void,
  handleDeleteModalOpen: (multipleDelete: boolean, bookingId?: string) => void,
  defaultPendingBookingToOpenId?: string
}

const BookingTableRow: FunctionComponent<BookingTableRowProps> = ({
  className = "",
  booking,
  index,
  rowOpen,
  status,
  setRowOpen,
  setFormOpen,
  setBooking,
  bookingForm,
  isSelected,
  handleValueChange,
  handleDeleteModalOpen,
  defaultPendingBookingToOpenId
}) => {
  const intl = useIntl();

  const handleOpenRow = (bookingId: string) => {
    const isRowCurrentlyOpen = rowOpen[bookingId] ?? false

    setRowOpen({
      ...rowOpen,
      [bookingId]: !isRowCurrentlyOpen,
    })
  }

  const handleOpenForm = () => {
    setBooking(booking);
    setFormOpen(true);
  }

  useEffect(() => {
    if (defaultPendingBookingToOpenId === booking.id) {
      handleOpenForm()
    }
  }, [defaultPendingBookingToOpenId])

  const formInitialValues: BookingFields = {
    isSelected: isSelected,
    bookingId: booking.id,
    quantity: bookingForm?.quantity || booking.quantity,
    supplierId: bookingForm?.supplierId || booking.defaultSupplierId,
    price: bookingForm?.price || booking.purchasePrice,
    comment: bookingForm?.comment || "",
    orderItemId: booking.orderItemId,
  }

  const handleFormValuesChanges = (values: FormikValues, checked = isSelected, isCheckboxAction = false) => {
    handleValueChange({...values, isSelected: (checked || !isCheckboxAction)})
  }

  const handleSupplierChange = (supplierId: string, setFieldValue: (field: string, value: string|number) => void ) => {
    setFieldValue("supplierId", supplierId)
    bookingService.getBookingPriceForSupplier(booking.id, supplierId)
      .then((bookingPrice: BookingPriceResponse) => {
        setFieldValue("quantity", bookingPrice.quantity)
        setFieldValue("price",  bookingPrice.unitPrice)
      })
      .catch((error: ApiError) => {
        toastUtils.errorToast(error.message)
      })
  }

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={BookingForPurchaseCreationValidationSchema}
      validateOnChange={false}
      validateOnBlur
      onSubmit={(values) => handleFormValuesChanges(values)}
    >
      {({errors, touched, values, setFieldValue, submitForm}) => (
        <EpowForm id={`booking_${index}`} className={className}>
          <div  className="epow-custom-table-row">
            <div className={`${className} epow-custom-table-row-main`} onClick={() => handleOpenRow(booking?.id)}>
              <Col className={status !== BookingStatus.TO_DO ? "d-none" : "p-2"} xs={1} onClick={(event) => { { event.preventDefault(); event.stopPropagation() } }}>
                <Input
                  className="me-2"
                  type="checkbox"
                  checked={values.isSelected}
                  onChange={() => handleFormValuesChanges(values, !isSelected, true)}
                />
              </Col>
              <Col className="d-flex" onClick={event => event.stopPropagation()}>
                {booking?.serviceName}
              </Col>
              <Col>
                {status === BookingStatus.TO_DO ? booking?.defaultSupplierName : booking?.purchase?.supplier?.name}
              </Col>
              <Col>
                <TextLink to={`${ORDERS_PATH}/${booking?.orderId}#${booking?.orderItemId}`}><>{orderUtils.getOrderRefDisplay(booking.orderCode, booking.orderRef, booking.orderCustomerName)}</></TextLink>
              </Col>
              <Col>
                <>{dateUtils.formatDateDayJs(booking?.date)} {dateUtils.formatLocalTime(booking?.hour)}</>
              </Col>
              <Col>
                {status === BookingStatus.TO_DO ? booking?.orderPax : (booking?.quantity || "-")}
              </Col>
            {status === BookingStatus.TO_DO ? 
            (<>
              <Col>
                <div className="d-flex">
                {booking?.pax}
                  {booking.createdFromOrderUpdate &&
                    <div className="ms-2">
                      <Popover iconName="TriangleExclamation" text="booking_created_after_order_item_update" iconClassName="color-warning"></Popover>
                    </div>
                  }
                </div>
              </Col>
              <Col>
                <Icon name="Trash" size={IconSizeType.XS} className="cursor-pointer" onClick={() => handleDeleteModalOpen(false, booking.id)}/>
              </Col>
            </>) : 
            (<Col>
              <Icon name="PaperClip" size={IconSizeType.XS} className="cursor-pointer" onClick={() => handleOpenForm()}/>
            </Col>)}
            </div>

            {rowOpen[booking?.id] && status !== BookingStatus.CLOSED && (
              <div className="epow-custom-table-row-details">
                <div className="d-flex flex-row w-100">
                  <Col className="col-3 px-2">
                    <FormInput
                      id={`quantity_${index}`}
                      label="booking_quantity"
                      className="w-auto"
                      type="number"
                      aria-label={intl.formatMessage({id: "editable_field"})}
                      value={values?.quantity}
                      onChange={(e) => setFieldValue("quantity", e.target.value)}
                      onBlur={submitForm}
                      error={errors?.quantity}
                      touched={touched?.quantity}
                      required
                    />
                  </Col>
                  <Col className="col-6 px-2">
                    <FormAutocompleteSelectPageable
                      id={`supplier_${index}`}
                      label={intl.formatMessage({id: "booking_supplier"})}
                      value={values?.supplierId}
                      onChange={(value) => handleSupplierChange(value, setFieldValue)}
                      onBlur={submitForm}
                      required
                      error={errors.supplierId}
                      touched={touched.supplierId}
                      fetchData={(page, filters) => supplierService.getSuppliersPaginated(page, {...filters, serviceId: booking?.serviceId})}
                      filterFieldName="name"
                    />
                  </Col>
                  <Col className="col-3 px-2">
                    <FormInput
                      id={`price_${index}`}
                      label="booking_price"
                      type="number"
                      value={numberUtils.formatPrice(values?.quantity * values.price)}
                      disabled
                    />
                  </Col>
                  <Col className="col-12 px-2">
                    <FormInput
                      id={`booking_comment_${index}`}
                      label="booking_comment"
                      className="w-auto"
                      type="textarea"
                      aria-label={intl.formatMessage({id: "editable_field"})}
                      value={values?.comment}
                      onChange={(e) => setFieldValue("comment", e.target.value)}
                      onBlur={submitForm}
                      error={errors?.comment}
                      touched={touched?.comment}
                    />
                  </Col>
                </div>
              </div>
            )}
          </div>
        </EpowForm>
      )}
    </Formik>
  )
}

export default BookingTableRow
